<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    if (!this.$liff.isInClient()) {
      this.$router.push({ name: 'CreatedGroupList' });
    }
  }
};
</script>
